.specialist {
  text-align: center;
  background-color: $white;
  box-shadow: 0px 0px 19px 0px rgba(0, 0, 0, 0.08);
  height: 100%;
  &__img {
    @include cover;
    padding-top: 110%; }
  &__bottom {
    padding: 25px 10px; } }
