.header {
  $this: &;
  position: relative;
  z-index: 10;
  &__inner {
    height: $header-height;
    @include flex(space-between, cennter);
    @include lg {
      height: $header-height-mob; } }

  &__logo {
    @include sm {
      flex-grow: 1; } }
  &__top {
    background-color: $white;
    #{$this}__inner {
      padding-top: 10px;
      padding-bottom: 10px; } }
  &__contacts {
    flex-grow: 1;
    @include lg {
      .contact {
        &:first-child {
 }          // display: none
        &:last-child {
 } } }          // display: none
    @include sm {
      display: none; } }


  &__bottom {
    background-color: $blue-light;
    @include xs {
      height: 0; } }
  &__bottom-els {
    @include flex(space-around, center);
    flex-grow: 1;
    @include xs {
      display: none; } }
  &__bottom-mob {
    display: none;
    text-align: center;
    @include xs {
      display: block; } }
  &__bottom-mobile {
    // text-align: center
    @include flex(center, center);
    .contact {
      width: auto;
      margin-left: 3px;
      margin-right: 3px; } }
  &__contacts-mob {
    display: none;
    @include sm {
      display: block; } }
  &__lang {
    @include flex(flex-start, center); }
  &__time {
    color: $white;
    height: 100%;
    @include flex(flex-start, center);
    .icon {
      margin-right: 15px; }
    @include xs {
      color: $c-text;
      justify-content: center;
      margin-bottom: 10px; } }
  &__nav {
    @include lg {
      position: absolute;
      display: none;
      width: 100%;
      top: 100%;
      z-index: 1;
      background-color: $white;
      left: 0;
      padding-top: 20px;
      padding-bottom: 20px;
      padding-left: 15px;
      padding-right: 15px; } }
  &__burger {
    display: none;
    margin-left: 20px;
    align-items: center;
    @include lg {
      display: flex; } } }

.burger {
  display: block;
  width: 28px;
  height: 28px;
  span:after, span:before {
    content: "";
    position: absolute;
    left: 0;
    top: 9px; }
  span:after {
    top: 18px; }
  span {
    position: relative;
    display: block; }
  span, span:after, span:before {
    width: 100%;
    height: 2px;
    background-color: currentColor;
    transition: transform 0.3s;
    backface-visibility: hidden;
    border-radius: 2px; }
  &.is-active span {
    background-color: transparent; }
  &.is-active span:before {
    transform: rotate(45deg) translate(-1px, 0px); }
  &.is-active span:after {
    transform: rotate(-45deg) translate(6px, -7px); } }


.contacts {
  @include flex(space-around, center, row, wrap);
  height: 100%;
  @include lg {
    justify-content: flex-start;
    padding-left: 15px;
    padding-right: 15px; }
  @include xs {
    padding-left: 0;
    padding-right: 0; } }

.contact {
  @include inline-flex(flex-start, center);
  @include lg {
    width: 50%; }
  @include sm {
    justify-content: center; }
  @include xs {
    width: 100%;
    margin-bottom: 10px; }
  .icon {
    fill: $accent;
    margin-right: 10px; }
  &--white {
    color: $white;
    .icon {
      fill: currentColor; } } }

.lang {
  position: relative;
  &:hover {
    .lang__list {
      display: block; } }
  &__title {
    position: relative;
    padding-right: 20px;

    @include before {
      @include icon-arrow(10, 10, 1, currentColor, -45);
      position: absolute;
      top: .2em;
      right: 0; } }
  &__list {
    display: none;
    position: absolute;
    z-index: 1;
    top: 100%;
    width: 100%;
    left: 0; } }
