.section {
  $this: &;
  padding-top: 6%;
  padding-bottom: 6%;
  position: relative;
  &__title {
    text-align: center;
    margin-bottom: 45px;
    @include lg {
      margin-bottom: 35px; }
    @include md {
      margin-bottom: 20px; }
    @include sm {
      margin-bottom: 15px; } }
  &__title-sm {
    margin-bottom: 10px !important; }
  &__subttl {
    text-align: center;
    margin-bottom: 50px;
    @include lg {
      margin-bottom: 35px; }
    @include md {
      margin-bottom: 20px; }
    @include sm {
      margin-bottom: 15px; } }
  &__text-bd {
    border-top: 2px solid  $accent;
    padding-top: 20px; }
  &__content {
    @include flex(flex-start, normal, row, wrap);
    position: relative;
    z-index: 1; }
  &__content-text {
    padding-top: 50px;
    width: 50%;
    position: relative;
    z-index: 1;
    @include lg {
      padding-top: 30px; }
    @include md {
      padding-top: 15px;
      width: 100%; }
    @include sm {
      padding-top: 0; } }
  &__content-img {
    width: 50%;
    @include flex(center, normal, column);
    @include md {
      display: none;
      // position: absolute
      // bottom: 0
      // right: 0
 } }      // z-index: 0
  &__content-img-right {
    img {
      &[data-loaded="true"] {
        transform: translate(10%, 0); } } }

  &--bg {
    @include cover; }
  &--overlay {
    @include before {
      @include coverdiv;
      z-index: 0;
      background-color: rgba($blue, .85); } }
  &--top {
    padding-top: 0; }

  &--content {
    overflow: hidden;
    @include before {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      width: 70%;
      z-index: 0;
      background-image: linear-gradient(to left, #dfe8f2, #fff); }
    .section-dots {
      bottom: 0;
      left: 0;
      width: 30%;
      height: 90%; }
    h2, .h2 {
      margin-bottom: 45px;
      @include lg {
        margin-bottom: 35px; }
      @include md {
        margin-bottom: 20px; }
      @include sm {
        margin-bottom: 15px; } }
    p {
      @include notlast {
        margin-bottom: 20px;
        @include lg {
          margin-bottom: 15px; }
        @include sm {
          margin-bottom: 10px; } } }
    ul {
      // list-style-type: disc
 }      // padding-left: 15px
    li {
      margin-bottom: 10px;
      &::before {
        content: "\2022";
        display: inline-block;
        margin-right: 5px; } }
    a {
      text-decoration: underline;
      &.contact {
        text-decoration: none; }
      &.btn {
        text-decoration: none; } }


    #{$this}__title {
      h2, .h2 {
        margin-bottom: 0; } } }

  &--content-no-grad {
   @include before {
    display: none; } }

  &--form {
    padding: 0;
    background-color: #f4f4f4;
    background-repeat: no-repeat;
    background-position: 100% 50%;
    background-size: auto 100%;
    z-index: 5;
    #{$this}__form {
      margin-top: -25px;
      margin-bottom: -25px; } }
  &--gray {
    background-color: #f7f7f7; }
  &--no-pad {
    padding: 0; }
  &--page {
    padding-top: 0;
    .container {
      @include clr; }
    a {
      text-decoration: underline;
      &.contact {
        text-decoration: none; }
      &.btn {
        text-decoration: none; } }
    p {
      @include notlast {
        margin-bottom: 20px;
        @include lg {
          margin-bottom: 15px; }
        @include sm {
          margin-bottom: 10px; } } }
    ul {}
    li {
      margin-bottom: 10px;
      &::before {
        content: "\2022";
        display: inline-block;
        margin-right: 5px; } }
    img {
      &.is-left {
        float: left;
        margin-right: 30px;
        margin-bottom: 30px;
        @include sm {
          margin-right: 15px;
          margin-bottom: 15px; } }
      &.is-right {
        float: right;
        margin-left: 30px;
        margin-bottom: 30px;
        @include sm {
          margin-left: 15px;
          margin-bottom: 15px; } } } } }

.section-dots {
  @include image('bg-dot.png');
  background-size: auto;
  background-repeat: repeat;
  position: absolute;
  z-index: 0; }

.s-specialists {
  .section-dots {
    top: 0;
    left: 0;
    width: percentage(360/1920);
    height: 80%; } }

.s-faq {
  .section-dots {
    top: 5%;
    right: 0;
    width: percentage(590/1920);
    height: 90%; } }

.s-map {
  .section-dots {
    top: 30px;
    right: 0;
    height: 60%;
    width: 50%; } }
