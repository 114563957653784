$fz:    15px;
$fz-sm: 14px;
$fz-xs: 12px;

$c-text: #273c53;
$accent: #f24841;

$white: #fff;
$black: #000;

$blue: #27346d;
$blue-darken: #4b4980;
$blue-dark: #080637;
$blue-light: #4758a5;
$blue-lighten: #5668bc;
$blue-lightest: #5d70c8;
$blue-100: #a4aac3;
$blue-link: #3655d4;

$gray: #c1c1c1;



$header-height: 72px;
$header-height-mob: 50px;
