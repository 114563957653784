.services {
  &__items {
    [class*="col-"] {
      margin-bottom: 30px;
      @include sm {
        margin-bottom: 15px; } } } }


.service-item {
  $this: &;
  background-color: $blue;
  color: $white;
  @include flex(flex-start, center);
  padding: 15px 35px;
  height: 100%;
  &__icon {
    margin-right: 15px;
    .icon {
      font-size: 36px; } }

  &--lg {
    @include cover;
    position: relative;
    justify-content: center;
    align-items: center;
    height: calc(100% - 30px);
    @include before {
      @include coverdiv;
      z-index: 0;
      background-color: rgba($blue, .85); }
    @include lg {
      min-height: 115px; }
    #{$this}__title {
      position: relative;
      z-index: 1; } } }
