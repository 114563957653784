.contacts-sct {
  @include md {
    margin-bottom: 15px; }
  &__contacts {
    @include md {
      @include flex(space-between, normal, row, wrap); } }

  &__btn {
    @include md {
      text-align: center; } } }

.contacts-block {
  position: relative;
  padding-left: 25px;
  margin-bottom: 55px;
  @include lg {
    margin-bottom: 40px; }
  @include md {
    margin-bottom: 15px; }
  &__icon {
    color: $accent;
    position: absolute;
    top: .1em;
    left: 0;
    font-size: 18px; }
  &__title {
    margin-bottom: 10px; }
  &__text {
    a {
      display: block; } } }
