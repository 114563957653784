.table {
  @include sm {
    overflow: auto; }
  table {
    border: 1px solid  #ddd;
    @include sm {
      width: 930px; } }
  td, th {
    text-align: center;
    vertical-align: middle;
    padding-left: 10px;
    padding-right: 10px;
    @include lg {
      padding-top: 15px;
      padding-bottom: 15px; }
    @include md {
      padding-top: 10px;
      padding-bottom: 10px; } }
  th {
    font-weight: 400;
    @include bold;
    @extend .h4;
    padding-top: 20px;
    padding-bottom: 20px; }

  tr {

    &:nth-child(odd) {
      background-color: $white; }
    &:nth-child(even) {
      background-color: #fafafa; } }
  td {
    padding-top: 25px;
    padding-bottom: 25px; } }

