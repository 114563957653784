.nav {
  $this: &;
  color: $white;
  @include lg {
    color: $c-text; }
  ul {
    font-size: 0; }
  li {
    display: inline-block;
    text-align: center;
    @include lg {
      display: block;
      margin-bottom: 20px;
      text-align: left;
      position: relative;
      &:first-child {
        a, span {
          border: 0; } } }
    @include xs {
      margin-bottom: 10px; }
    &.has-dropdown {
      position: relative;
      a, span {
        position: relative;
        @include before {
          @include icon-arrow(8, 8, 1, currentColor, -45);
          position: absolute;
          pointer-events: none;
          top: 50%;
          transform: translate(0, -50%) rotate(-45deg);
          right: 20px;
          @include xl {
            right: 5px; }
          @include lg {
            top: .3em;
            transform: translate(0, 0) rotate(-45deg); } }
        @include lg {
          // right: -50px
          position: static; } }
      &:hover {
        a, span {
          @include before {
            transform: translate(0, -50%) rotate(-225deg); } }

        #{$this}--dropdown {
          display: block; } } }
    &:first-child {
      a, span {
        border-left: 1px solid  $blue-lighten; } } }
  a, span {
    font-size: $fz;
    display: flex;
    align-items: center;
    justify-content: center;
    height: $header-height;
    padding-left: 40px;
    padding-right: 40px;
    @include tr(background-color .3s, color .3s);
    border-right: 1px solid  $blue-lighten;
    @include hover {
      text-decoration: none;
      background-color: $blue-lighten; }
    @include xl {
      padding-left: 15px;
      padding-right: 15px; }
    @include lg {
      display: inline;
      padding: 0;
      padding-right: 20px;
      border: 0;
      height: auto; } }



  &--dropdown {
    position: absolute;
    top: 100%;
    left: -1px;
    right: 0;
    z-index: 1;
    background-color: $blue-lighten;
    display: none;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.27);
    @include lg {
      position: static;
      box-shadow: none;
      background-color: transparent;
      padding-top: 20px; }
    li {
      display: block;
      text-align: center;
      @include lg {
        border-bottom: 1px solid  $gray;
        margin-bottom: 0 !important;
        &:first-child {
          padding-bottom: 10px; }
        @include notfirst {
          padding-top: 10px;
          padding-bottom: 10px; } } }
    a, span {
      height: 50px;
      padding-left: 10px;
      padding-right: 10px;
      justify-content: center;
      @include before {
        display: none; }
      @include hover {
        background-color: $blue-lightest;
        color: #acbbff; } } }

  &--footer {
    // color: $blue-darken
    @include lg {
      color: $white; }
    #{$this}--dropdown {
      position: static;
      background-color: transparent;
      padding-top: 10px;
      a, span {
        @include before {
          display: none; } } }
    li {
      display: block;
      text-align: left;
      @include notlast {
        margin-bottom: 12px; }
      &:first-child {
        a, span {
          border: 0; } }
      &.has-dropdown {
        @include before {
          display: none; }
        &:hover {
          a, span {
            @include before {
              @include rotate(-225);
              top: .6em; } } }
        a, span {
          position: relative;
          display: inline-block;
          padding-right: 15px;
          @include before {
            @include icon-arrow(8, 8, 1, currentColor, -45);
            position: absolute;
            pointer-events: none;
            top: .4em;
            right: 0; } } } }

    a, span {
      border: 0;
      padding: 0;
      // padding: 0 !important
      height: auto;
      display: inline;
      @include hover {
        background-color: transparent;
        text-decoration: underline; }
      @include xl {
        padding-left: 0;
        padding-right: 0; } } } }

