.form {
  text-align: center;
  background-color: $white;
  padding: 55px 35px 35px;
  box-shadow: 0px 2px 22px 0px rgba(0, 0, 0, 0.09);
  @include lg {
    padding: 40px 25px 25px; }
  @include md {
    padding: 30px 20px 20px; }
  @include sm {
    padding: 25px 15px 15px; }
  &__title {
    margin-bottom: 15px;
    @include md {
      margin-bottom: 10px; } }
  &__subttl {
    margin-bottom: 25px;
    @include lg {
      margin-bottom: 15px; }
    @include md {
      margin-bottom: 10px; } }
  &__field {
    margin-bottom: 25px;
    @include lg {
      margin-bottom: 15px; }
    @include sm {
      margin-bottom: 10px; } }
  &__btn {
    padding-top: 10px;
    @include notlast {
      margin-bottom: 25px;
      @include lg {
        margin-bottom: 15px; }
      @include sm {
        margin-bottom: 10px; } } }
  &__bottom {
    color: $blue-100;
    font-size: 13px; } }
