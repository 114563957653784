.slider {
  &__wrap {
    position: relative;
    .lSAction {
      @extend .container;
      position: absolute;
      width: 100%;
      left: 50%;
      transform: translate(-50%, -50%);
      top: 50%;
      .lSPrev,
      .lSNext {
        @include xs {
          display: none; } }
      .lSPrev {
        left: -15px; }
      .lSNext {
        right: -15px; } } }
  &__prev,
  &__next {
    position: absolute;
    color: $blue-100;
    top: 35%;
    height: 28px;
    width: 28px;
    border: 2px solid currentColor;
    @include inline-flex(center, center);
    background-color: transparent;
    border-radius: 50%;
    @include xs {
      color: $white; } }
  &__prev {
    left: -50px;
    @include lg {
      left: -30px; }
    @include xs {
      left: 0; }
    @include before {
      @include icon-arrow(8, 8, 2, currentColor, 45);
      margin-left: .2em; } }
  &__next {
    right: -50px;
    @include lg {
      right: -30px; }
    @include xs {
      right: 0; }
    @include before {
      @include icon-arrow(8, 8, 2, currentColor, 225);
      margin-right: .2em; } }

  &--hero {
    .slide {
      height: calc(100vh - (#{$header-height} * 2));
      @include flex(center, normal, column);
      @include lg {
        height: calc(100vh - (#{$header-height-mob} * 2)); }
      @include xs {
        height: calc(100vh - #{$header-height-mob}); } }
    &__wrap {
      .lSPager {
        position: absolute;
        bottom: 30px;
        left: 0;
        width: 100%;
        z-index: 1;
        a {
          background-color: transparent !important;
          width: 10px;
          height: 10px;
          @include hidetext; }
        li {
          border: 1px solid  $white;
          border-radius: 50%;
          width: 10px;
          height: 10px;
          margin-right: 10px;
          margin-left: 10px;
          padding: 0 !important;
          display: inline-flex !important;
          @include inline-flex(center, center);
          &.active {
            height: 16px;
            width: 16px;
            a {
              background-color: $white !important; } } } } } }

  &--specialists {
    .slide {
      padding-top: 20px;
      padding-bottom: 20px; }
    &__wrap {
      .lSAction {
        .lSPrev {
          left: -50px; }
        .lSNext {
          right: -50px; } } } } }

.slide {
  &__img {
    display: block; } }
