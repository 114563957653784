.input {
  color: $blue-100;
  input {
    height: 30px;
    width: 100%;
    border-bottom: 1px solid  $blue-100;
    &:focus {
      border-color: $accent; } }
  &--icon {
    position: relative;
    .icon {
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      left: 0; }
    input {
      padding-left: 30px; } } }
