.breadcrumbs {
  font-size: 13px;
  li {
    display: inline-block; }
  .is-active {
    pointer-events: none;
    color: #bdbdbd; }

  &--white {
    color: $white;
    .ic-active {
      color: $gray; } } }
