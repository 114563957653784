.faq {
  @extend .row; }

.question {
  background-color: $white;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.11);
  border-bottom: 2px solid  $accent;
  margin-bottom: 30px;
  @include sm {
    margin-bottom: 15px; }

  &__wrap {
    @extend .col-md-6; }

  &__title {
    @include flex(flex-start, center);
    position: relative;
    width: 100%;
    padding: 35px;
    padding-right: 65px;
    @include before {
      @include icon-arrow(8, 8, 1, currentColor, -45);
      position: absolute;
      top: 50%;
      transform: translate(0, -50%) rotate(-45deg);
      right: 35px; }
    &.is-active {
      padding-bottom: 13px; }
    @include lg {
      padding: 25px;
      padding-right: 40px;
      @include before {
        right: 25px; } }
    @include md {
      padding: 20px;
      padding-right: 35px;
      @include before {
        right: 20px; } }
    .icon {
      font-size: 30px;
      margin-right: 12px; } }
  &__icon {
    display: inline-block;
    color: $gray; }
  &__text {
    padding-left: 35px;
    padding-right: 35px;

    // display: none
    max-height: 0;
    overflow: hidden;
    padding-bottom: 0;
    a {
      color: $blue-link;
      text-decoration: underline; }
    @include lg {
      padding-left: 25px;
      padding-right: 25px; }
    @include md {
      padding-left: 20px;
      padding-right: 20px; }
    &.is-active {
      max-height: none;
      overflow: visible;
      padding-bottom: 35px;
      @include lg {
        padding-bottom: 25px; }
      @include md {
        padding-bottom: 20px; } } } }
