.gallery {
  height: auto;

  &__wrap {
    li {
      @include before {
        display: none; } }
    @include md {
      margin-bottom: 15px; }
    .lSAction {
      .lSPrev,
      .lSNext {
        background-image: none;
        background-color: rgba($black, .9);
        width: 55px;
        height: 55px;
        @include inline-flex(center, center);
        color: $white;
        opacity: 1;
        border-radius: 50%;
        @include lg {
          width: 40px;
          height: 40px;
          @include before {
            width: 10px;
            height: 10px; } }
        @include xs {
          width: 30px;
          height: 30px; } }
      .lSPrev {
        left: 30px;
        @include before {
          @include icon-arrow(16, 16, 2, currentColor, 45);
          margin-left: .2em; }
        @include lg {
          left: 15px; }
        @include xs {
          left: 5px; } }
      .lSNext {
        right: 30px;
        @include before {
          @include icon-arrow(16, 16, 2, currentColor, 225);
          margin-right: .2em; }
        @include lg {
          right: 15px; }
        @include xs {
          right: 5px; } } } } }
