@include font('proximanova-regular', 'proximanova-regular-webfont');
@include font('proximanova-bold', 'proximanova-bold-webfont');
@include font('proximanova-light', 'proximanova-light-webfont');
@include font('proximanova-semibold', 'proximanova-semibold-webfont');
@include font('proximanova-regularit', 'proximanova-regularit-webfont');

@mixin reg {
  font-family: 'proximanova-regular', Arial, sans-serif; }
@mixin bold {
  font-family: 'proximanova-bold', Arial, sans-serif; }
@mixin light {
  font-family: 'proximanova-light', Arial, sans-serif; }
@mixin semibold {
  font-family: 'proximanova-semibold', Arial, sans-serif; }
@mixin italic {
  font-family: 'proximanova-regularit', Arial, sans-serif; }
