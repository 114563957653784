.order {
  margin-top: 80px;
  margin-bottom: 55px;
  position: relative;
  @include lg {
    padding-left: 15px; }
  @include sm {
    padding-right: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 25px;
    margin-bottom: 25px; }
  @include before {
    @include coverdiv;
    z-index: 0;
    background-color: rgba($blue, .5); }
  .row {
    position: relative;
    z-index: 1; }
  &__form {
    margin-top: -50px;
    margin-bottom: -30px;
    @include sm {
      margin-top: 0;
      margin-bottom: 0; } }
  &__title {
    margin-bottom: 20px;
    @include lg {
      margin-bottom: 15px; }
    @include md {
      margin-bottom: 10px; } }
  &__subttl {
    margin-bottom: 25px;
    max-width: 360px;
    @include lg {
      margin-bottom: 15px; }
    @include md {
      margin-bottom: 10px; } }
  &__contacts {
    @include flex(flex-start, center);
    .contact {
      @include flex(flex-start, center);
      margin-bottom: 10px; }
    @include lg {
      display: block; } }
  &__contacts-left {
    margin-right: 70px;
    @include lg {
      margin-right: 0; } } }
