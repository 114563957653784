.hero {
  &__block {
    height: 100%;
    @include flex(center, normal, column);
    padding-left: 75px;
    position: relative;
    z-index: 1;
    @include lg {
      padding-left: 50px; }
    @include md {
      padding-left: 30px; }
    @include sm {
      padding-left: 15px; }
    @include xs {
      padding-left: 0;
      margin-bottom: 30px; } }
  &__title {
    margin-bottom: 20px;
    @include md {
      margin-bottom: 10px; } }
  &__subttl {
    margin-bottom: 15px;
    @include md {
      margin-bottom: 10px; } }
  &__img {
    @include xs {
 } } }      // display: none
