body {
  background: #FFF;
  line-height: 1.4;
  font-size: $fz;
  @include reg;
  color: $c-text;
  @include no-scroll;
  @include md {
    font-size: 14px; } }

input, textarea {
  font-family: inherit;
  color: inherit;
  font-size: inherit;
  @include placeholder {
    font-family: inherit;
    font-size: inherit; } }

.icon {
  display: inline-block;
  fill: currentColor;
  font-size: inherit; }

.pt-0 {
  padding-top: 0 !important; }
.pb-0 {
  padding-bottom: 0 !important; }

.container {
  @include xlmin {
    max-width: 1440px; } }

.logo {
  display: inline-block;
  font-size: 0;
  max-height: 100%;
  height: $header-height - 20px;
  img {
    max-height: 100%; }
  @include hover {
    text-decoration: none; }
  &--header {
    @include lg {
      height: $header-height-mob - 10px; } } }


a:not(.btn) {
  @include hover {
    text-decoration: underline; } }

.btn {
  display: inline-block;
  color: $white;
  @include bold;
  text-align: center;
  min-width: 210px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 11px;
  font-size: 16px;
  background-color: $accent;
  border: 1px solid  $accent;
  @include tr(color .3s, background-color .3s, border-color .3s);
  @include hover {
    background-color: transparent;
    color: $accent; }
  @include lg {
    padding-top: 7px;
    padding-bottom: 8px; }
  @include xs {
    width: 100%;
    min-width: 0; }
  &--shadow {
    box-shadow: 0 0 10px $accent; }
  &--full {
    width: 100%; }
  &--lg {
    padding-top: 16px;
    padding-bottom: 17px;
    min-width: 240px;
    @include lg {
      min-width: 210px; } }
  &--transp {
    background-color: transparent;
    color: $accent;
    @include hover {
      background-color: $accent;
      color: $white; } } }


.link-arrow {
  color: $white;
  position: relative;
  @include inline-flex(flex-start, center);
  @include hover {
    // .link-arrow__arrow
 }    //   width: 50px
  &__title {
    margin-right: 20px; }
  &__arrow {
    position: relative;
    width: 40px;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
    display: inline-block;
    transition: transform .3s, width .3s;
    &::after {
      content: '';
      @include icon-arrow(8, 8, 1, currentColor, 225);
      position: absolute;
      top: 50%;
      transform: translate(0, -50%) rotate(225deg);
      right: 0; }
    &::before {
      content: '';
      width: 100%;
      position: absolute;
      height: 1px;
      background-color: currentColor;
      top: 50%;
      right: -1px;
      transform: translate(0, -50%);
      transition: width .3s; } } }

.price {
  @extend .h1;
  small {
    @include reg;
    font-size: $fz;
    &:first-child {
      font-size: 16px;
      @include bold; } }
  &--white {
    color: $white; } }

.map {
  position: relative;
  padding-top: 50%;
  @include xs {

    padding-top: 60%; }
  iframe {
    @include coverdiv; } }


