.main {
  padding-top: 15px;
  &__breadcrumbs {
    margin-bottom: 15px; }
  &__title {
    margin-bottom: 60px;
    @include lg {
      margin-bottom: 40px; }
    @include md {
      margin-bottom: 30px; }
    @include sm {
      margin-bottom: 15px; } }
  &--blue {
    background-color: #212649; } }


