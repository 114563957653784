.feature {
  $this: &;
  text-align: center;
  margin-bottom: 30px;
  &__icon {
    color: $accent;
    margin-bottom: 10px;
    position: relative;
    width: 100px;
    height: 100px;
    margin-left: auto;
    margin-right: auto;
    @include flex(center, center);
    @include before {
      position: absolute;
      top: 0;
      left: 20px;
      transform: rotate(45deg);
      width: 100%;
      height: 50%;
      border: 1px solid currentColor;
      border-bottom: 0;
      border-bottom-color: transparent;
      border-top-left-radius: 100px;
      border-top-right-radius: 100px; }
    .icon {
      font-size: 50px;
      position: relative;
      z-index: 3; } }
  &__title {
    margin-bottom: 10px; }
  &__text {
    max-width: 285px;
    margin-left: auto;
    margin-right: auto; }

  &--text {
    text-align: left;
    margin-bottom: 45px;
    @include lg {
      margin-bottom: 30px; }
    @include md {
      margin-bottom: 15px; }
    @include xs {
      text-align: center; }
    #{$this}__icon {
      margin-left: 0;
      margin-right: 0;
      color: $c-text;
      @include xs {
        margin-left: auto;
        margin-right: auto; } }
    #{$this}__text {
      margin-left: 0;
      margin-right: 0;
      @include xs {
        margin-left: auto;
        margin-right: auto; } } } }
