.blocks {
  padding-bottom: 6%; }

.block {
  @include notlast {
    margin-bottom: 30px; }
  &__title {
    margin-bottom: 20px;
    @include md {
      margin-bottom: 15px; }
    @include sm {
      margin-bottom: 10px; } }
  &__text {
    p {
      margin-bottom: 25px;
      @include lg {
        margin-bottom: 15px; }
      @include sm {
        margin-bottom: 10px; } } }
  &__img {
    padding-top: 60%;
    @include cover;
    position: relative;
    overflow: hidden;
    @include lg {
      padding-top: 90%; }
    @include md {
      padding-top: 40%;
      margin-bottom: 15px; }
    @include xs {
      padding-top: 50%; } }
  &__price {
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: $blue;
    height: 200px;
    width: 200px;
    padding-top: 55px;
    padding-left: 35px;
    // +flex(center, center)
    border-radius: 50%;
    transform: translate(30%, 40%);
    @include xs {
      width: 150px;
      height: 150px;
      padding-top: 40px;
      padding-left: 25px; } }
  &__content {
    padding-left: 35px;
    @include xl {
      padding-left: 0; } }
  &__btns {
    .btn {
      &:first-child {
        margin-right: 30px;
        @include lg {
          margin-right: 10px; }
        @include xs {
          margin-right: 0;
          margin-bottom: 5px; } } } } }
