strong {
  font-weight: 400;
  @include bold; }

small {
  font-size: inherit; }

em, i {
  font-style: normal;
  @include italic; }

h1, h2, h3, h4, h5, h6 {
  font-weight: 400; }

.h1, h1 {
  @include css-lock(28, 38, 320, 1199, font-size);
  @include bold;
  line-height: 1;
  &--white {
    color: $white; } }

.h2, h2 {
  @include css-lock(24, 38, 320, 1199, font-size);
  @include bold;
  line-height: 1;
  &--white {
    color: $white; }
  &--lg {
    @include css-lock(24, 45, 320, 1199, font-size); } }

.h3, h3 {}

.h4, h4 {
  @include css-lock(17, 21, 320, 1199, font-size);
  line-height: 1;
  @include bold;
  &--white {
    color: $white; } }

.h5, h5 {}

.h6, h6 {}

.text {
  &--white {
    color: $white; }
  &--blue-100 {
    color: $blue-100; } }

.title-under {
  position: relative;
  padding-bottom: 8px;
  @include before {
    height: 1px;
    width: 65px;
    position: absolute;
    background-color: currentColor;
    left: 0;
    bottom: 0; } }
