.error {
  @include xs {
    text-align: center;
    padding-top: 30%;
    padding-bottom: 30%; }
  &__title {
    margin-bottom: 10px; }
  &__subttl {
    margin-bottom: 10px; }
  &__404 {
    color: $white;
    @include xs {
      display: none; }
    @include bold;
    span {
      // +css-lock(100, 385, 320, 1700, font-size)
      position: absolute;
      transform: translate(0, -50%);
      top: 50%;
      display: inline-block;
      font-size: 385px;
      @include xl {
        font-size: 320px; }
      @include lg {
        font-size: 270px; }
      @include md {
        font-size: 200px; }
      @include sm {
        font-size: 150px; }
      &:nth-child(1) {
        z-index: 2;
        left: 0;
        width: percentage(2/3); }
      &:nth-child(2) {
        z-index: 0;
        width: 30%;
        left: percentage(2/3); } }
    img {
      position: relative; } } }
