.about {
  position: relative;
  background-repeat: no-repeat;
  background-position: 0 0;
  .section-dots {
    top: -75px;
    bottom: -30px;
    left: 0;
    width: 45%; }
  [class*='col-'] {
    @include md {
 } }      // position: static
  &__bg {
    position: absolute;
    z-index: 1;
    top: 0;
    bottom: 0;
    left: 0;
    @include cover;
    width: percentage(825/1920);
    @include before {
      @include coverdiv;
      background-color: rgba($blue, .85); }
    @include md {
      display: none; } }
  &__bg-md {
    display: none;
    position: absolute;
    z-index: 1;
    top: 0;
    left: -15px;
    bottom: 0;
    right: -15px;
    @include cover;
    @include before {
      @include coverdiv;
      background-color: rgba($blue, .85); }
    @include md {
      display: block; } }

  &__content {
    position: relative;
    z-index: 2;
    height: 100%;
    @include flex(center, normal, column);
    padding-right: 80px;
    @include xl {
      padding-right: 60px; }
    @include lg {
      padding-right: 40px; }
    @include md {
      padding-right: 20px;
      // margin-bottom: 50px
      padding-top: 15px;
      padding-bottom: 15px; }
    @include xs {
      padding-bottom: 10px;
      padding-top: 10px;
      padding-left: 0;
      padding-right: 0; } }
  &__title {
    margin-bottom: 25px;
    position: relative;
    z-index: 1;
    @include lg {
      margin-bottom: 15px; }
    @include md {
      margin-bottom: 10px; } }
  &__text {
    position: relative;
    z-index: 2; }
  &__features {
    padding-left: 70px;
    padding-top: 30px;
    @include xl {
      padding-left: 60px;
      padding-top: 15px; }
    @include lg {
      padding-left: 40px; }
    @include md {
      padding-left: 0; } } }

.about-info {
  &__items {
    background-color: #f7f7f7;
    padding-top: 55px;
    padding-bottom: 55px;
    @include lg {
      padding-top: 40px;
      padding-bottom: 40px; }
    @include md {
      padding-top: 30px;
      padding-bottom: 30px; }
    @include sm {
      padding-right: 15px;
      padding-bottom: 15px; } }
  &__items-wrap {
    @include flex(flex-start, normal, row, wrap); }
  &__bottom {
    background-color: #27346d;
    color: $white;
    padding-top: 30px;
    padding-bottom: 30px;
    .icon {
      font-size: 35px;
      margin-right: 10px; } }
  &__bottom-title {
    @include bold;
    @include css-lock(17, 21, 320, 1199, font-size);
    line-height: 1; }
  &__bottom-left {
    @include flex(flex-start, center);
    @include md {
      margin-bottom: 15px;
      display: block;
      text-align: center; } } }

.about-info-item {
  text-align: center;
  width: 25%;
  @include xs {
    width: 50%; }
  &__icon {
    margin-bottom: 10px;
    .icon {
      font-size: 60px;
      fill: $gray;
      @include sm {
        font-size: 50px; } } }
  &__title {
    color: $blue-light;
    font-size: 32px;
    text-transform: uppercase;
    position: relative;
    margin-bottom: 10px;
    @include bold;
    // padding-bottom: 8px
    position: relative;
    @include before {
      height: 1px;
      width: 55px;
      background-color: $gray;
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
      bottom: 0; } } }
